import { graphql } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import React, { useMemo } from 'react';

import {
  Divider,
  IconGrid,
  List,
  Mosaic,
  Reviews,
  theme,
} from '@dogtainers/cms-blocks';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { useLocation } from '@reach/router';

import { useFindRetreatIdByTitle } from '../hooks';
import SiteLayout, { ResponsiveContext } from '../layouts/site.layout';
import { CmsRetreatQuery } from '../types';
import { generateBreadcrumbsList } from '../utils';
import { Booking } from '../views';
import { TemplateProps } from './template.types';
import { usePetManager } from '../hooks/useCanLoadPetManager';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
    },
    container: {
      minHeight: '620px',
    },
    navOffset: {
      marginTop: 60,
    },
    green: {
      color: ' #6EAF56',
    },
    h1: {
      fontSize: '28px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '3rem',
      },
    },
    h6: {
      fontSize: '0.875rem',
      fontFamily: 'Cabin,Montserrat',
      color: '#818181',
    },
    heading: {
      paddingBottom: '20px',
    },
    map: {
      paddingTop: '30px',
    },
    bookingForm: {
      position: 'absolute',
      right: '1.5rem',
      top: 0,
      maxWidth: 'calc(33.3% - 40px)',
      [theme.breakpoints.down('sm')]: {
        position: 'unset',
        marginTop: '20px',
        maxWidth: '100%',
      },
    },
    getInTouchEmail: {
      fontWeight: 'bold',
      paddingTop: '10px',
    },
  };
});

export const query = graphql`
  query getRetreatWithTemplate($retreatId: ID!) {
    cms {
      retreats {
        getRetreat(id: $retreatId) {
          id
          slug
          title
          subtitle
          metaData {
            title
            description
            meta {
              name
              content
            }
          }
          photos {
            id
            src
            name
          }
          summary
          description
          getInTouch
          features {
            label
          }
          badges {
            id
            src
            name
          }
          pets {
            label
          }
          extras {
            label
          }
          rooms {
            title
            summary
          }
          rating
          totalReviews
          reviews {
            name
            rating
            description
            created_at
            avatar {
              src
            }
          }
          address {
            latitude
            longitude
            street
            suburb
            state
            postcode
            locationLink
          }
          contact {
            phone
            email
          }
          createdOn
          savedOn
        }
      }
      availabilities {
        getAvailabilityByRetreat(retreatId: $retreatId) {
          id
          retreatId
          extras {
            id
            cost
            type
          }
          rooms {
            quantity
            type
            numberOfBeds
            petType
            prices {
              easterPrice
              defaultPrice
              christmasPrice
            }
          }
        }
      }
    }
  }
`;

type RetreatTemplateProps = TemplateProps & {
  data: CmsRetreatQuery;
};

export const Retreat: React.FC<RetreatTemplateProps> = ({
  ...templateProps
}) => {
  const {
    data: { cms },
  } = templateProps;
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { pathname } = useLocation();
  const darwinRetreatId = useFindRetreatIdByTitle(
    'Bees Creek Boarding Kennels',
  );

  // Pet Manager
  const { canLoad: canLoadPetManager, pmToken, pmLocation } = usePetManager(cms.retreats.getRetreat.metaData);


  const [retreat, rooms] = useMemo(
    () => [
      cms.retreats.getRetreat,
      cms.availabilities.getAvailabilityByRetreat?.rooms,
    ],
    [cms],
  );

  const breadcrumbs = useMemo(
    () => generateBreadcrumbsList(pathname),
    [pathname],
  );

  return (
    <SiteLayout
      breadcrumbs={breadcrumbs}
      isRetreat
      cmsMeta={cms.retreats.getRetreat.metaData}
      {...templateProps}
    >
      <BreadcrumbJsonLd itemListElements={breadcrumbs} />
      <ResponsiveContext.Consumer>
        {({ breakpoint }) => (
          <Box className={classes.navOffset}>
            {retreat && (
              <Box>
                <Container>
                  <Grid>
                    <Box>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: retreat.title,
                        }}
                        variant="h1"
                        align="center"
                        className={classes.h1}
                      />
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: retreat.subtitle,
                        }}
                        variant="h6"
                        align="center"
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      marginTop="10px"
                    >
                      {retreat.rating && (
                        <>
                          <Typography variant="h6" className={classes.h6}>
                            {retreat.rating}&nbsp;
                          </Typography>
                          <StarIcon className={classes.green} />
                        </>
                      )}
                      <Typography variant="h6" className={classes.h6}>
                        &nbsp;
                        {retreat.reviews &&
                          ` based on ${retreat.totalReviews || retreat.reviews?.length
                          } reviews`}
                        {` • `}
                        <Link
                          target="_blank"
                          href={retreat.address.locationLink}
                        >
                          {retreat.address?.street}, {retreat.address?.suburb}
                          &nbsp;
                          {retreat.address?.state}, {retreat.address?.postcode}
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Container>

                {retreat.photos && (
                  <Mosaic images={retreat.photos.map((photo) => photo.src)} />
                )}

                <Container className={classes.root}>
                  <Grid
                    item
                    xs={12}
                    md={rooms ? 12 : 12}
                    className={classes.container}
                  >
                    {retreat.features && <List list={retreat.features} />}

                    <Grid>
                      <Box>
                        {retreat.summary && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: retreat.summary,
                            }}
                          />
                        )}
                        {retreat.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: retreat.description,
                            }}
                          />
                        )}
                        {retreat.getInTouch && (
                          <div className={classes.getInTouchEmail}>
                            Get in Touch:{' '}
                            <Link href={`mailto:${retreat.getInTouch}`}>
                              {retreat.getInTouch}
                            </Link>
                          </div>
                        )}
                      </Box>
                      <Divider />
                    </Grid>


                    <Grid>
                      <Box>
                        <Typography variant="h4" className={classes.heading}>
                          Book Online
                        </Typography>
                        <div data-toggle="pm-portal" data-token={pmToken} data-location={pmLocation}></div>
                        {!canLoadPetManager && (
                          <Typography variant="body1" style={{ color: '#FF3333' }}>
                            Our Customer Portal is not supported by the web browser you are using. Please upgrade to a modern browser such as Chrome or Firefox.
                          </Typography>
                        )}
                      </Box>
                      <Divider />
                    </Grid>


                    {retreat.badges && (
                      <Grid>
                        <Box>
                          <Typography variant="h4" className={classes.heading}>
                            Badges:
                          </Typography>
                          <IconGrid
                            icons={retreat.badges.map(({ src }) => ({ src }))}
                            numbCols={isMobile ? 3 : 5}
                          />
                        </Box>
                        <Divider />
                      </Grid>
                    )}
                    {!!retreat.pets?.length && (
                      <List heading="Pets catered for:" list={retreat.pets} />
                    )}
                    {!!retreat.extras?.length && (
                      <List heading="Extras:" list={retreat.extras} />
                    )}
                  </Grid>
                  <Grid>
                    {retreat.reviews && (
                      <Grid>
                        <Typography variant="h4" className={classes.heading}>
                          Reviews:
                        </Typography>
                        <Reviews reviews={retreat.reviews} />
                      </Grid>
                    )}
                  </Grid>

                </Container>
              </Box>
            )}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </SiteLayout>
  );
};

export default Retreat;
